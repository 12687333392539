* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

a {
	text-decoration: none;
}

a:active {
	color: white;
}

html {
	scroll-behavior: smooth;
}

html, body, #root {

	width: 100%;
	min-height:100vh;
	overflow-x: hidden;
}

body {
	
	margin: 0;
    position: relative;
	background: #141414;
	color: white;
	font-family: 'Quicksand', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {

	display: flex;
	flex-direction: column;
}

/* App */
header {

	background-color: transparent;
	transition: background-color 0.25s !important;
}

.condition {
	
	margin: 0 auto;
	width: fit-content;
	position: relative;
	display: flex;
	align-items: center;
	font-size: 1.25em;
}

.condition img {

	width: 2em;
	position: absolute;
	left: 0;
}

.condition div {
	padding: 0 2em;
}

.details {

	margin: 0.5em 0 !important;
	padding: 0 8px 10px;
	background: #fff2;
	border-radius: 0.725rem;
	text-align: center;
}

.preview {
	margin: 1rem 0;
}

/* Loader */
.loader-content {

	position: fixed;
	bottom: 40px;
	left: 50%;
	display: flex;
	align-items: center;
	padding: 5px 10px;
	border-radius:24rem;
}

/* Menubar */
nav, .backdrop {

	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	z-index: 1200;
}

nav {

	min-width: 15rem;
	max-width: 100vw;
	background: #202020;
	overflow-x: hidden;
	overflow-y: auto;
}

.backdrop {

	width: 100vw;
	background: #0008;
}

.menubar-icon {
	z-index: 2000;
}

nav ul {
	padding-top: 5rem;
}

nav li {

	list-style: none;
	display: flex;
	align-items: center;
}

/* BG Animations */
#bg, #view-bg, #fake-bg {

	position: absolute;
	height: 100%;
	width: 100%;
	transition: opacity 0.5s;
	z-index: -1;
	overflow: hidden;
}

#view-bg, #fake-bg {
	top: 0;
}

#fake-bg {
	background: #141414;
}

/* Day Animations */
.dayAnim, .nightAnim {

	position: absolute;
	width: 100%;
}

.dayAnim, .dark-clouds {

	height: 40vw;
	max-height: 250px;
	display: flex;
	align-items: center;
}

.dayAnim img, .dark-clouds img {

	position: relative;
	width: 90vw;
	top: -15vw;
}

/* Night Animations */
.nightAnim {
	height: 100%;
}

.star, .star-body, .shooting-star-box  {
	position: absolute;
}

.star-body {

	opacity: 0.75;
	top: 50%;
	left: 50%;
	width: 60%;
	height: 60%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	background: #fff;filter: drop-shadow(0 0 1px #5f94ff)
	drop-shadow(0 0 1px #4b87ff);
}

.shooting-star-box {
	bottom: 100%;
}

.shooting-star {

	width: 2px;
	height: 100px;
	background: linear-gradient(45deg, #92b5ff, #5389ff, #0000ff00);
	border-radius: 24px;
	filter: drop-shadow(0 0 6px #699bff);
}

/* Rainy/Cloudy Animations */
.dark-clouds img {

	position: absolute;
	width: 100vw;
	top: -20vw;
	filter: invert(0.3) brightness(0.5);
}

.rain {

	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	z-index: -1;
}

.droplet {

	position: absolute;
	width: 2px;
	height: 25px;
	background: linear-gradient(45deg, #ffffff, #dee8ff, #0000ff00);
}

/* About */
.about .feature-box {

	margin: 1rem 0 0.5rem;
	padding: 1rem;
	padding-top: 0.5rem;
	border-radius: 1.5rem;
	background: #fff2;
	color: #ffffffdf;
}

.about li {

	list-style-position: inside;
	list-style-type: none;
}

.about .feature-box li {

	margin: 0.5rem 0;
	padding-left: 3rem;
	list-style-type: disc;
	font-weight: 400;
	color: #ffffffc0;
	text-indent: -1.5rem;
}

.about a {

	color: #00ff95;
	font-weight: 500;
}

.about a:hover {
	text-decoration: underline;
}

@media only screen and (max-width: 600px) {
	
	.overview .temps {

		max-width: 320px;
		justify-content: space-evenly;
	}
	.dayAnim img {

		width: 95vw;
		top: -18vw;
	}
	.dark-clouds img {
		top: -11vw;
	}
}